import { Button, Descriptions, Modal } from "antd";
import dayjs from "dayjs";
import { CAREPLAN_API, getReferenceDescription, mapReferenceType } from "../../../helpers/Common";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {useAuth} from '../../../auth'
import {randomString} from 'react-inlinesvg/lib/helpers'

export const CareplanDetailModal = ({ isModalOpen, setIsModalOpen, careplan, forceUpdate }) => {
  const [mentorInfo, setMentorInfo] = useState({} as any);
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const handleStop = () => {
    axios.delete(CAREPLAN_API, { data: { careplan_id: careplan.uid } })
      .then(response => {
        console.log(response)
        forceUpdate();
      })
    setIsModalOpen(false);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  const handleRenderFooter = () => {
    let footerSet: any = [
      <Button onClick={handleCancel}>Đóng</Button>
    ]
    if (!careplan) return footerSet;
    if (careplan.status !== 'ACTIVE') {
      footerSet = [
        <Button onClick={handleCancel}>Đóng</Button>
      ]
    } else {
      if (currentUser &&
        (currentUser.role !== 'SUPER_ADMIN_SYSTEM' &&
          currentUser.role !== 'SUPER_ADMIN_BRANCH' &&
          currentUser.role !== 'OWNER') ) {
        footerSet = [
          <Button key={randomString(5)} onClick={handleStop} danger>Dừng kế hoạch</Button>,
          <Button key={randomString(4)} onClick={handleCancel}>Đóng</Button>
        ]
      }
    }
    return footerSet
  }



  useEffect(() => {
    // axios.get(ACCOUNT_SUMMARY_API, { params: { target_user_id: careplan.mentor_id } })
      // .then(response => setMentorInfo(response.data.data))
    setMentorInfo(careplan.mentor_info)
  }, [careplan])

  return (
    careplan && <Modal
      title='Chi tiết kế hoạch sức khoẻ'
      open={isModalOpen}
      onCancel={handleCancel}
      cancelText='Dừng kế hoạch'
      width={800}
      footer={handleRenderFooter}
    >
      <Descriptions column={2} layout="vertical" style={{ marginBlock: 20 }} labelStyle={{ fontWeight: 500 }}>
        <Descriptions.Item label="Tên kế hoạch" key={1}>{careplan.plan_name}</Descriptions.Item>
        {mentorInfo &&
          <Descriptions.Item label="Bác sĩ hỗ trợ" key={2}><Button icon={<i className="fa-solid fa-user-doctor"></i>} onClick={() => navigate(`/account/id/${mentorInfo.uid}`)}>{mentorInfo.fullname}</Button></Descriptions.Item>
        }
        {!mentorInfo &&
          <Descriptions.Item label="Bác sỹ hỗ trợ" key={2}><span>Không có</span></Descriptions.Item>
        }
        <Descriptions.Item label="Loại kế hoạch" key={3}>{getReferenceDescription(careplan.plan_type)}</Descriptions.Item>
        <Descriptions.Item label="Mô tả kế hoạch" key={4}>{careplan.description}</Descriptions.Item>
        <Descriptions.Item label="Lặp lại" key={5}>{careplan.period === 'WEEK' ? 'Hàng tuần' : careplan.period === 'MONTH' ? 'Hàng tháng' : 'Hàng ngày'}</Descriptions.Item>
        <Descriptions.Item label="Thời gian áp dụng" key={6}>{`${dayjs(careplan.start_datetime * 1000).format('DD/MM/YYYY')} - ${dayjs(careplan.end_datetime * 1000).format('DD/MM/YYYY')}`}</Descriptions.Item>
        <Descriptions.Item label="Lấy thông tin" key={7}>{mapReferenceType(careplan.plan_type)}</Descriptions.Item>
        <Descriptions.Item label="Giá trị mục tiêu" key={8}>{careplan.target_value}</Descriptions.Item>
        <Descriptions.Item label="Nhắc nhở" key={9}>{dayjs(careplan.notice_at, 'HH:mm:ss').format('HH:mm')}</Descriptions.Item>
      </Descriptions>
    </Modal >
  )
}
