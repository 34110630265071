import { Card, ConfigProvider, DatePicker, Rate, Select, Space, Table, Typography } from 'antd'
import Column from 'antd/es/table/Column'
import { useAuth } from 'app/modules/auth'
import { DOCTOR_RATING_LIST_API, EMPLOYEE_API, ORG_API, getAppointmentStatusLabel } from 'app/modules/helpers/Common'
import { Permission } from 'app/modules/helpers/Permission'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState, useCallback } from 'react'

import {
  usePagination
} from 'utils/functional/layout'

import { useDebounce } from 'use-debounce'

const { RangePicker } = DatePicker
const { Text } = Typography

const OrgFilterList = ({ org, setOrg }) => {
  const { auth } = useAuth()
  const [orgList, setOrgList] = useState([{ ...auth?.organization }] as any[])
  /* const [filter, setFilter] = useState('')*/
  /* const [filterDebounce] = useDebounce(filter, 600)*/

  /* useEffect(() => {
   *   axios.get(ORG_API, { params: { page: 1, name: filterDebounce } })
   *     .then((response) => setOrgList(response.data.data.items))
   * }, [filterDebounce])*/

  const getOrgData = useCallback(({ page, searchTerm }) => {
    return axios
      .get(ORG_API, { params: { page, name: searchTerm } });

  }, []);

  const { onScroll, onSearchDebounce } = usePagination(orgList, setOrgList, getOrgData);

  return (
    <Space direction='vertical' size={4} className='me-5'>
      <Text className='fw-bolder fs-7 text-uppercase'>Tổ chức</Text>
      <Select
        options={orgList.map((org) => ({ value: org.uid, label: org.name }))}
        placeholder='Tất cả'
        style={{ width: 200 }}
        allowClear={false}
        showSearch
        filterOption={false}
        onPopupScroll={onScroll}
        onSearch={(value) => onSearchDebounce(value)}
        onSelect={(value) => {
          setOrg(value)

        }}
        onClear={() => setOrg(undefined)}
        value={org}
      />
    </Space>
  )
}

const DoctorFilterList = ({ org, doctor, setDoctor }) => {
  const [doctorList, setDoctorList] = useState([] as any[])
  const [filter, setFilter] = useState('')
  const [filterDebounce] = useDebounce(filter, 600);

  const [_org, _setOrg] = useState('');

  const getDoctorData = useCallback(({ page, searchTerm }) => {
    let params = {
      page,
      fullname: searchTerm,
      contact: searchTerm,
      org_id: org,
      role: JSON.stringify(['DOCTOR'])
    }
    return axios.get(EMPLOYEE_API, { params: params });

  }, [org]);

  const { onScroll, onSearch, onSearchDebounce } = usePagination(doctorList, setDoctorList, getDoctorData);

  useEffect(() => {
    setFilter('')
  }, [org])

  useEffect(() => {
    if (org !== _org) {
      onSearch('');
      _setOrg(org);
    }
  }, [filterDebounce, org, _org, _setOrg, onSearch])

  return (
    <Space direction='vertical' size={4} className='me-5'>
      <Text className='fw-bolder fs-7 text-uppercase'>Bác sĩ</Text>
      <Select
        options={doctorList.map((org) => ({ value: org.uid, label: org.fullname }))}
        placeholder='Tất cả'
        style={{ width: 200 }}
        allowClear
        showSearch
        filterOption={false}
        onSearch={(value) => onSearchDebounce(value)}
        onPopupScroll={onScroll}
        onSelect={(value) => {
          setDoctor(value)
          setFilter('')
        }}
        onClear={() => setDoctor(undefined)}
        value={doctor}
      />
    </Space>
  )
}

export const RatingListPage = () => {
  const [appointments, setAppointments] = useState([] as any)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [fromDate, setFromDate] = useState(dayjs().add(-2, 'month').startOf('day'))
  const [toDate, setToDate] = useState(dayjs())
  const { auth, permission } = useAuth()
  const [selectedOrg, setSelectedOrg] = useState(auth?.organization?.uid)
  const [selectedDoctor, setSelectedDoctor] = useState(undefined)
  const pageSize = 10
  const [selectedRating, setSelectedRating] = useState(undefined)

  useEffect(() => {
    axios.get(DOCTOR_RATING_LIST_API, {
      params: {
        org_id: selectedOrg,
        doctor_id: selectedDoctor,
        local_start_datetime: fromDate.startOf('day').format('YYYY/MM/DD HH:mm:ss'),
        local_end_datetime: toDate.endOf('day').format('YYYY/MM/DD HH:mm:ss'),
        page: page,
        rating: selectedRating,
        per_page: pageSize
      }
    }).then((response) => response.data.data)
      .then((data) => {
        setAppointments(data.items)
        setMaxPage(data.max_page)
      })
  }, [page, fromDate, toDate, selectedOrg, selectedDoctor, selectedRating])

  return (
    <>
      <Card className='mb-5' styles={{ body: { paddingBlock: 15, paddingInline: 32 } }}>
        <Space>
          {permission(Permission.SwichOrganization) && (
            <OrgFilterList
              org={selectedOrg}
              setOrg={(org) => {
                if (org && org !== selectedOrg) {
                  setSelectedOrg(org)
                  setSelectedDoctor(undefined)
                  setPage(1)
                }
              }}
            />
          )}
          {permission(Permission.ManageOrganization) && (
            <DoctorFilterList
              org={selectedOrg}
              doctor={selectedDoctor}
              setDoctor={(doctor) => {
                setSelectedDoctor(doctor)
                setPage(1)
              }}
            />
          )}
          <Space direction='vertical' size={4} className='me-5'>
            <Text className='fw-bolder fs-7 text-uppercase'>Đánh giá</Text>
            <Select
              options={Array.from(Array(5).keys()).map(value => ({ value: value + 1, label: <Space size="small">{value + 1}<Rate value={value + 1} className='fs-7 fa-fw' disabled /></Space> }))}
              placeholder='Tất cả'
              style={{ width: 200 }}
              allowClear
              filterOption={false}
              onSelect={(value) => {
                setSelectedRating(value)
                setPage(1)
              }}
              onClear={() => setSelectedRating(undefined)}
              value={selectedRating}
            />
          </Space>
        </Space>

        <Space>
          <Space direction='vertical' size={4}>
            <Text className='fw-bolder fs-7 text-uppercase'>Thời gian</Text>
            <RangePicker
              value={[fromDate, toDate]}
              allowClear={false}
              allowEmpty={false}
              onChange={(dates) => {
                if (dates) {
                  setFromDate(dates[0] as any)
                  setToDate(dates[1] as any)
                  setPage(1)
                }
              }}
            />
          </Space>
        </Space>
      </Card>
      <Card>
        <ConfigProvider theme={{ components: { Table: { headerBg: '#fff', rowExpandedBg: 'white' } } }}>
          <Table
            dataSource={appointments}
            rowKey='uid'
            pagination={{
              defaultCurrent: 1,
              total: maxPage * pageSize,
              showSizeChanger: false,
              onChange: (page) => setPage(page),
              current: page,
            }}
            onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
            onRow={() => ({ className: 'fs-7 text-gray-600' })}
            size='small'
            locale={{ emptyText: 'Không có dữ liệu' }}
            expandable={{
              expandedRowRender: (record: any) => <div className="tw-flex tw-flex-col text-gray-600"
                style={{ paddingLeft: '48px' }}
              >
                <p className="tw-font-semibold" style={{ margin: 0 }}>{`Ý kiến khách hàng:`}</p>
                <p style={{ margin: 0 }}>{`${record.feedback}`}</p>
              </div>,
              expandRowByClick: true,
              rowExpandable: (record: any) => record?.feedback?.trim()
            }}
          >
            <Column title='Khách hàng' dataIndex='patient_info' render={(value) => value.fullname} />
            <Column title='Nhóm bệnh' dataIndex={['disease_info', 'name']} />
            <Column title='Ngày tư vấn' dataIndex='start_time' render={(value) => dayjs(value * 1000).format('DD/MM/YYYY')} />
            <Column title='Ngày đánh giá' dataIndex='rating_at' render={(value) => dayjs(value * 1000).format('DD/MM/YYYY')} />
            <Column title='Trạng thái' dataIndex='status' render={(value) => getAppointmentStatusLabel(value)} />
            <Column title='Đánh giá' dataIndex='rating' render={(value) => <Rate value={value} disabled className='fs-7' />} />

          </Table>
        </ConfigProvider>
      </Card>
    </>
  )
}
