import { Card, ConfigProvider, Table } from "antd"
import { useEffect, useState } from "react"
import { useAuth } from "app/modules/auth"

const pageSize = 10

export const CommonTable = ({ getData, extra = <></>, title = <></>, columnList = <></>, forceReload = 0 }) => {
  const [orgList, setOrgList] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  const { auth } = useAuth()

  const orgOnly = auth?.role === 'SUPER_ADMIN_BRANCH'

  useEffect(() => {
    if (page <= maxPage) {
      getData({ page })
        .then(response => response.data.data)
        .then(response => {
          setOrgList(response.items)
          setMaxPage(response.max_page)
        })

    }

  }, [page, maxPage, orgOnly, getData, forceReload])

  return (
    <Card
      extra={
        extra
      }
      title={
        title
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
              colorText: '#78829d',
            }
          },
        }}>
        <Table
          dataSource={orgList}
          rowKey="uid"
          pagination={{ defaultCurrent: 1, total: Math.max(maxPage * pageSize, orgList.length), showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fw-boldper fs-7' })}
          size='middle'
        >
          {columnList}
        </Table>
      </ConfigProvider>
    </Card >
  )
}
