import {
  useState, useEffect, useCallback
} from 'react'

import { useDebounce } from 'use-debounce'

const onScrollWithPagination = (e, page, maxPage, setPage, setLoading, loading) => {
  if (!loading) {
    const { currentTarget: target } = e

    const scrollPercent = (target.scrollTop + target.clientHeight) / target.scrollHeight

    const newPage = page + 1

    if (scrollPercent > 0.9 && newPage <= maxPage) {
      setLoading(true)
      setPage(page + 1)
    }
  }
}

const usePagination = (data, setData, getData, process = e => e) => {

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [allowProcess, setAllowProcess] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const [_searchTerm, _setSearchTerm] = useState('');
  const [searchTermDebounce] = useDebounce(_searchTerm, 500);

  const api = useCallback(async () => {

    if (allowProcess) {
      const { data: { success, data: apiData } } = await getData({ page, searchTerm });

      if (success) {
        const { items, max_page } = apiData;

        setMaxPage(max_page);
        if (page === 1) {
          setData(process(items));
        } else {
          if (items.length) {
            if (!data.map(e => e.uid).includes(process(items)[0].uid)) {
              setData([...data, ...process(items)]);
            }

          }

        }
      }
      setAllowProcess(false);
    }

  }, [data, page, setData, getData, searchTerm, setMaxPage, allowProcess, setAllowProcess, process])

  useEffect(() => {
    api()
  }, [page, searchTerm, api])

  const onScroll = useCallback((e) => {
    if (!allowProcess) {
      const { currentTarget: target } = e

      const scrollPercent = (target.scrollTop + target.clientHeight) / target.scrollHeight

      const newPage = page + 1

      if (scrollPercent > 0.9 && newPage <= maxPage) {
        setAllowProcess(true);
        setPage(newPage);
      }
    }


  }, [page, maxPage, allowProcess, setAllowProcess]);

  const onSearch = useCallback((value) => {
    setAllowProcess(true);
    setSearchTerm(value);
    setPage(1);

  }, [setPage, setSearchTerm, setAllowProcess]);

  useEffect(() => {
    onSearch(searchTermDebounce);

  }, [searchTermDebounce, onSearch])

  const onSearchDebounce = useCallback((value) => {
    _setSearchTerm(value);

  }, [_setSearchTerm])

  return {
    onScroll,
    onSearch,
    onSearchDebounce,
  }

}


export {
  onScrollWithPagination,
  usePagination,
}
