import {Button, Card, Col, DatePicker, Pagination, Row, Select, Space} from 'antd'
import {EXECUTE_EXPORT_API, GET_EXPORT_HISTORY_API, ORG_API} from 'app/modules/helpers/Common'
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import {useAuth} from '../../../auth'

enum STATUS {
  "FAILED" =	"Không hoàn thành",
  "FINISHED" =	"Đã hoàn thành",
  "INPROGRESS" ="Đang xử lý"
}
interface ExportDataItem {
  created_at: number;
  creator_id: string;
  finised_at: number;
  last_scan_at: number;
  local_timezone: number;
  local_year_month: string;
  org_id: string;
  path_file: string;
  status: string;
  type: string;
  uid: string;
  org_name: string;
}

interface ExportData {
  items: ExportDataItem[];
  per_page: number;
  max_page: number;
}


export const SysReport = ({ doctor = undefined as any }) => {

  const  { auth} = useAuth();
  // @ts-ignore
  const orgSelectOptions =[{value: auth.organization.uid, label: auth.organization.name}] as any[]

  const [orgId, setOrgId] = useState(orgSelectOptions[0].value)
  const [exportData , setExportData] = useState({items: [], max_page: 10, per_page:1} as ExportData)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [orgList, setOrgList] = useState(orgSelectOptions)

  const syncOrgList = (page: number, syncedData: []) => {
    if (auth?.role === "SUPER_ADMIN_BRANCH") return;
    axios.get(ORG_API, { params: { page: page } })
      .then(response => response.data.data)
      .then(data => {
        const synced = data.items
          .filter((item: { is_active: boolean }) => item.is_active)
          .map((item: { uid: any; name: any }) => ({value: item.uid, label: item.name}));
        const currentData =  [...syncedData, ...synced]
        if (data.max_page > page ) {
          // @ts-ignore
          syncOrgList(page + 1, currentData)
        } else {
          setOrgList(currentData);
        }
      })
  }
  const syncReportHistory = () => {
    setCurrentPage(1);
    axios.get(GET_EXPORT_HISTORY_API, { params: { page: 1, org_id: orgId }, })
      .then(response => response.data.data)
      .then(data => {
        setExportData(data)
      })
  }

  const executeReport= () => {
    axios.post(EXECUTE_EXPORT_API, {
        org_id: orgId,
        local_year_month: dayjs(currentDate).format('YYYY/MM'),
        local_timezone: 25200,
        type: 'ALL'
      })
      .then(response => response.data.data)
      .then(_ => {
        syncReportHistory()
      })
  }

  useEffect(() => {
    syncOrgList(1, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    syncReportHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, currentPage])

  return (
    <Card
      className="mb-8"
      title={
        <Space>
          <span>Chọn tổ chức</span>
          <Select options={orgList}
                  defaultValue={orgList[0].value}
                  disabled={auth?.role === "SUPER_ADMIN_BRANCH"}
            style={{minWidth: '200px', maxWidth: '350px'}}
                  onChange={setOrgId}/>
          <DatePicker picker={"month"} value={currentDate} onChange={(date) => setCurrentDate(date)} allowClear={false} />
          <Button icon={<i className="fa-solid fa-rotate"></i>} onClick={() => {
            syncReportHistory()
          }}/>
        </Space>
      }
      extra={
        <Button icon={<i className="fa-solid fa-folder-plus"></i>} onClick={() => {
          executeReport()
        }}>Xuất báo cáo</Button>
    }
    >
      <Row gutter={[10, 10]}>
        <Col span={8} >
          <span>Tổ chức</span>
        </Col>
        <Col span={4} >
          <span>Báo cáo tháng</span>
        </Col>
        <Col span={4} >
          <span>Thời gian xuất báo cáo</span>
        </Col>
        <Col span={4} >
          <span>Trạng thái báo cáo</span>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'center'}} span={2} >
          <span>Thao tác</span>
        </Col>
      </Row>
      {exportData.items.map((item, index) => (
        <Row gutter={[10, 10]} key={index} className={'mt-3'}>
          <Col span={8} >
            <span>{item.org_name}</span>
          </Col>
          <Col span={4} >
            <span>{dayjs(item.local_year_month).format('MM/YYYY')}</span>
          </Col>
          <Col span={4} >
            <span>{dayjs(item.finised_at * 1000).format('HH:mm:ss DD/MM/YYYY')}</span>
          </Col>
          <Col span={4} >
            <span>{STATUS[item.status]}</span>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center'}} span={2} >
            <Button className="text-hover-primary" icon={<i className="fa-solid fa-download"></i>}
                    download href={item.path_file} target="_blank"
                    disabled={item.status !== 'FINISHED'}
            />
          </Col>
        </Row>
      ))}
      {exportData.max_page > 1 &&
        <Pagination defaultCurrent={1} total={exportData.max_page * exportData.per_page} onChange={setCurrentPage} />
      }
    </Card>
  )
}
