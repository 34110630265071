import { Button, Card, DatePicker, Form, Input, Row, Select, Space, Typography, notification } from 'antd'
import { useAuth } from 'app/modules/auth'
import { Permission, getAvailableRolesForNewAccount } from 'app/modules/helpers/Permission'
import axios from 'axios'
import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import {
  usePagination
} from 'utils/functional/layout'

import { DEGREE_LIST_API, EMPLOYEE_API, ORG_API, getRoleDescription } from '../../../helpers/Common'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'


const { Text } = Typography

export const NewAccountPage = () => {

  const [showNotification, contextHolder] = notification.useNotification()

  const [selectedOrg, setSelectedOrg] = useState(undefined as any)
  const [orgList, setOrgList] = useState([] as any[]);
  const [filterTerm] = useState('');
  const [filterTermDebounce] = useDebounce(filterTerm, 600);
  const [degreeList, setDegreeList] = useState([] as any[])
  const { auth, permission } = useAuth()

  const allRoles = getAvailableRolesForNewAccount(auth?.role as string)
  const allRoleOptions = allRoles.map((role) => ({ label: getRoleDescription(role), value: role }))

  const navigate = useNavigate()
  const [form] = Form.useForm()

  const passwordMinLength = 6
  const phoneRegex = /^(0|84)(2(0[3-9]|1[0-6|8|9]|2[0-2|5-9]|3[2-9]|4[0-9]|5[1|2|4-9]|6[0-3|9]|7[0-7]|8[0-9]|9[0-4|6|7|9])|3[2-9]|5[5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])([0-9]{7})$/

  useEffect(() => {
    axios.get(DEGREE_LIST_API).then(
      response => {
        setDegreeList(response.data.data)
      }
    )
  }, [])

  /* const handleSearch = (value) => {
   *   setFilterTerm(value)
   * }*/

  useEffect(() => {
    // if (filterTermDebounce.length === 0) {
    //   setOrgList([])
    //   setSelectedOrg(null)
    // }
    // else {
    // filterTermDebounce.length > 0 && 
    permission(Permission.SwichOrganization) &&
      axios.get(ORG_API, { params: { page: 1, name: filterTermDebounce } })
        .then(response => response.data.data.items)
        .then(data => {
          let orgs = data.map(item => {
            return {
              value: item.uid,
              label: (
                <Space>
                  <Text>{item.name}</Text>
                </Space>
              ),
            }
          })
          setOrgList(orgs);
        })
    // }
  }, [filterTermDebounce, permission])

  const handleSelect = (value) => {
    setSelectedOrg(value)
  }

  const handleFormSubbmit = () => {
    form.validateFields().then((values) => {
      console.log('form', values)
      axios.post(EMPLOYEE_API, { ...values, birthday: values.birthday?.format('YYYY/MM/DD') })
        .then(response => {
          if (response.data.success) {
            navigate(`/account/id/${response.data.data.uid}`)
            // axios.post(MEDICAL_HISTORY_API, { pathological_medical_history: values.pathological_medical_history.join(';'), target_user_id: response.data.data.uid })
            //   .then(response => {
            //     if (response.data.success) {
            //       navigate(`/account/id/${response.data.data.uid}`)
            //     }
            //   })
          } else {

            const { data: { error: { message } } } = response;

            showNotification.open({
              message: 'Lỗi',
              description: message,
              placement: 'topRight',
              icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,

            })
          }
        })
    }).catch(() => { })
  };


  const getOrgData = useCallback(({ page, searchTerm }) => {
    return axios
      .get(ORG_API, { params: { page, name: searchTerm } });

  }, []);

  const { onScroll, onSearchDebounce } = usePagination(orgList, setOrgList, getOrgData, items => {

    const res = items.map(item => {
      return {
        value: item.uid,
        label: (
          <Space>
            <Text>{item.name}</Text>
          </Space>
        ),
        uid: item.uid
      }
    });

    return res;
  });

  return (
    <Card title='Thông tin tài khoản mới' actions={[
      <Row justify="end" style={{ paddingRight: 25 }}>
        <Space>
          <Button onClick={() => navigate(-1)}>Huỷ</Button>
          <Button type='primary' onClick={handleFormSubbmit}>Tạo tài khoản</Button>
        </Space>
      </Row>
    ]} >
      {contextHolder}
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={{ role: 'DOCTOR' }}>
        <Form.Item name='role' label="Loại tài khoản" rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]}>
          <Select options={allRoleOptions} />
        </Form.Item>
        {/* <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}>
          {({ getFieldValue }) =>
            getFieldValue('role') === 'PATIENT' ? (
              <Form.Item name="pathological_medical_history" label="Nhóm bệnh">
                <Select options={[{ value: "Tiểu đường" }, { value: "Tim mạch" }, { value: "Huyết áp" }]} mode="tags" />
              </Form.Item>
            ) : null
          }
        </Form.Item> */}
        {permission(Permission.SwichOrganization) &&
          <Form.Item name='target_org_id' label="Tổ chức" rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]}>
            <Select
              placeholder='Chọn tổ chức'
              options={orgList}
              showSearch
              // filterOption={filterOption}
              filterOption={false}
              onSearch={(value) => onSearchDebounce(value)}
              onPopupScroll={onScroll}
              //onSearch={handleSearch}
              popupMatchSelectWidth={false}
              //listHeight={400}
              //style={{ width: '100%' }}
              // suffixIcon={<i className="fa-solid fa-magnifying-glass fa-fw text-muted"></i>}
              onSelect={handleSelect}
              notFoundContent={null}
              value={selectedOrg}
              allowClear={true}
              onClear={() => {
                setSelectedOrg(null)
                setOrgList([])
              }} />
          </Form.Item>
        }
        <Form.Item name='fullname' label="Họ và tên" rules={[{ required: true, whitespace: true, message: 'Bắt buộc điền thông tin' }]}>
          <Input placeholder='Nguyễn Văn A' />
        </Form.Item>
        <Form.Item name="birthday" label="Ngày sinh">
          <DatePicker placeholder='Chọn ngày sinh' showNow={false} maxDate={dayjs()} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}>
          {({ getFieldValue }) =>
            getFieldValue('role') === 'PATIENT' ? (
              <Form.Item name='phone' label="Số điện thoại" rules={[{ message: 'Thông tin chưa đúng định dạng', pattern: phoneRegex }, { required: true, message: 'Bắt buộc điền thông tin' }]}>
                <Input placeholder='0123456789' />
              </Form.Item>
            ) :
              <Form.Item name='phone' label="Số điện thoại" rules={[{ message: 'Thông tin chưa đúng định dạng', pattern: phoneRegex }]}>
                <Input placeholder='0123456789' />
              </Form.Item>
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}>
          {({ getFieldValue }) =>
            getFieldValue('role') === 'PATIENT' ? (
              <Form.Item name='email' label="Email" rules={[{ message: 'Thông tin chưa đúng định dạng', type: "email" }]}>
                <Input placeholder='bacsy@metadoc.vn' autoComplete={'off'} />
              </Form.Item>
            ) :
              <>
                <Form.Item name='email' label="Email" rules={[{ message: 'Thông tin chưa đúng định dạng', type: "email" }, { required: true, message: 'Bắt buộc điền thông tin' }]}>
                  <Input placeholder='bacsy@metadoc.vn' autoComplete={'off'} />
                </Form.Item>
                <Form.Item name='practising_cert' label="Chứng chỉ hành nghề">
                  <Input placeholder='Chuyên khoa I' />
                </Form.Item>
                <Form.Item name='title' label='Chức danh'>
                  <Select mode="tags" />
                </Form.Item>
                <Form.Item name='degree' label='Học hàm, học vị'>
                  <Select options={degreeList.map(degree => ({ value: degree.acronym, label: degree.fullname }))} allowClear />
                </Form.Item>
              </>
          }
        </Form.Item>
        <Form.Item name='address' label="Địa chỉ">
          <Input placeholder="186 Điện Biên Phủ, P6, Q3, TP Hồ Chí Minh" />
        </Form.Item>
        <Form.Item name='nationality' label="Quốc tịch">
          <Input placeholder="Việt Nam" />
        </Form.Item>
        <Form.Item name='gender' label="Giới tính">
          <Select options={[{ label: 'Nam', value: 'MALE' }, { label: 'Nữ', value: 'FEMALE' }]} placeholder="Chọn giới tính" allowClear={true} />
        </Form.Item>
        <Form.Item name='summary' label='Tiểu sử'>
          <TextArea placeholder='Làm việc tại bệnh viện nhi đồng I' autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Form.Item name="password" label="Mật khẩu" rules={[{ required: true, message: 'Bắt buộc điền thông tin' }, { min: passwordMinLength, message: `Mật khẩu có độ dài ít nhất ${passwordMinLength} ký tự` }]}>
          <Input.Password autoComplete="new-password" placeholder={"Nhập mật khẩu"} />
        </Form.Item>
        <Form.Item name="password2" label="Nhập lại mật khẩu" dependencies={['password']}
          rules={[{ required: true, message: 'Bắt buộc điền thông tin' }, { min: passwordMinLength, message: `Mật khẩu có độ dài ít nhất ${passwordMinLength} ký tự` }, ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Mật khẩu không khớp'));
            },
          }),
          ]}>
          <Input.Password placeholder={"Nhập lại mật khẩu"} autoComplete={'off'} />
        </Form.Item>
      </Form >
    </Card >
  )
}
