import dayjs from 'dayjs'

export const getRoleDescription = (role) => {
  switch (role) {
    case 'SUPER_ADMIN_BRANCH':
      return 'Quản trị viên'
    case 'SUPER_ADMIN_SYS':
    case 'OWNER':
      return 'Quản trị hệ thống'
    case 'DOCTOR':
      return 'Bác sĩ'
    case 'CUSTOMER_CARE':
      return 'Chăm sóc khách hàng'
    case 'PATIENT':
      return 'Bệnh nhân'
    case 'NURSE':
      return 'Điều dưỡng'
    case 'ALL':
      return 'Tất cả'
  }
}

export const getQuestionDescription = (type) => {
  switch (type) {
    case 'TEXT':
      return 'Trả lời ngắn'
    case 'MULTIPLE_CHOICE':
      return 'Chọn một'
    case 'CHECKBOX':
      return 'Chọn nhiều'
    case 'DROPDOWN_LIST':
      return 'Menu chọn một'
    default:
      return 'Khác'
  }
}

export const getShortRoleDescription = (role) => {
  switch (role) {
    case 'SUPER_ADMIN_BRANCH':
      return 'NV'
    case 'OWNER':
      return 'NV'
    case 'DOCTOR':
      return 'BS'
    case 'CUSTOMER_CARE':
      return 'NV'
    case 'PATIENT':
      return 'BN'
    case 'NURSE':
      return 'NV'
  }
}

export const getGenderDescription = (gender) => {
  switch (gender.toUpperCase()) {
    case 'MALE':
      return 'Nam'
    case 'FEMALE':
      return 'Nữ'
    case 'UNKNOWN':
      return 'Khác'
    default:
      return gender
  }
}

export const getRelativeTime = (unixTimeStamp) => {
  // console.log('getRelativeTime', unixTimeStamp)
  let targetTime = new Date(unixTimeStamp * 1000)
  let currentTime = Date.now()

  let diff = (currentTime - targetTime.getTime()) / 1000
  if (diff < 60) {
    return 'Vừa xong'
  } else if (diff < 3600) {
    return `${Math.floor(diff / 60)} phút trước`
  } else if (diff < 86400) {
    return `${Math.floor(diff / 3600)} giờ trước`
  } else if (diff < 604800) {
    return `${Math.floor(diff / 86400)} ngày trước`
  } else if (diff < 2592000) {
    return `${Math.floor(diff / 604800)} tuần trước`
  } else if (diff < 31536000) {
    return `${Math.floor(diff / 2592000)} tháng trước`
  } else {
    return targetTime.toLocaleDateString()
  }
}

export const getUpcomingTimeDescription = (unixTimeStamp) => {
  let targetTime = new Date(unixTimeStamp * 1000)
  if (dayjs().isSame(targetTime, 'day')) {
    return 'hôm nay'
  } else if (dayjs().add(1, 'day').isSame(targetTime, 'day')) {
    return 'ngày mai'
  } else {
    return `ngày ${dayjs(targetTime).format('DD/MM/YYYY')}`
  }
}

export const getShortTimeDescription = (unixTimeStamp) => {
  let targetTime = dayjs(unixTimeStamp * 1000)
  let currentTime = dayjs()

  if (currentTime.isSame(targetTime, 'day')) {
    return targetTime.format('HH:mm')
  } else if (currentTime.isSame(targetTime, 'year')) {
    return targetTime.format('DD/MM')
  } else {
    return targetTime.format('YYYY')
  }
}

const capitalizeFirst = (s) => {
  return s[0].toUpperCase() + s.slice(1)
}

export const getChatTimeDescription = (unixTimeStamp) => {
  let targetTime = dayjs(unixTimeStamp * 1000)
  let currentTime = dayjs()

  if (currentTime.isSame(targetTime, 'day')) {
    return `Hôm nay ${targetTime.format('HH:mm')}`
  } else if (currentTime.diff(targetTime, 'day') <= 7) {
    return capitalizeFirst(targetTime.format('dddd HH:mm'))
  } else {
    return targetTime.format('DD/MM/YYYY HH:mm')
  }
}

export const getLongDateDescription = (unixTimeStamp) => {
  let targetTime = new Date(unixTimeStamp * 1000)
  return capitalizeFirst(dayjs(targetTime).format('dddd DD/MM/YYYY'))
}

export const onlineStatus = (unixTimeStamp) => {
  let targetTime = new Date(unixTimeStamp * 1000)
  let currentTime = Date.now()

  let diff = (currentTime - targetTime.getTime()) / 1000
  if (diff < 600) {
    return 'bg-success'
  } else if (diff < 1800) {
    return 'bg-warning'
  }
  return 'bg-secondary'
}

export const formatPhone = (phone) => {
  return phone.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '($1) $2-$3-$4')
}

export const generatePagination = (page: number, maxMage: number) => {
  let pageLinks: Array<{
    label: string
    active: boolean
    url?: string | null
    page: number | null
  }> = []
  // console.log('generatePagination', page, maxMage)

  let previousPage = {
    label: 'Previous',
    active: false,
    page: page > 1 ? page - 1 : null,
  }

  let nextPage = {
    label: 'Next',
    active: false,
    page: page < maxMage ? page + 1 : null,
  }
  pageLinks.push(previousPage)
  for (let index = 1; index <= maxMage; index++) {
    // let index = parseInt(pageIndex)
    // console.log(index)
    if (index >= 1 && index <= maxMage) {
      pageLinks.push({
        label: `${index}`,
        active: index === page,
        page: index,
      })
    }
  }
  pageLinks.push(nextPage)
  // console.log(pageLinks)
  return pageLinks.map((pageLink) => {
    return {
      ...pageLink,
      url: pageLink.page ? `/?page=${pageLink.page}` : null,
    }
  })
}

export const shortListPathologies = (pathologies) => {
  let shortList: string[] = []
  for (let pathology of pathologies) {
    if (pathology === 'Tiểu đường' || pathology === 'Tim mạch') {
      shortList.unshift(pathology)
    } else {
      shortList.push(pathology)
    }
  }
  if (shortList.length > 2) {
    let extra = shortList.length - 2
    shortList = shortList.slice(0, 2)
    shortList.push(`+${extra}`)
  }

  return shortList
}

export const stylePathology = (pathology) => {
  if (pathology[0] === '+') return 'badge-light-dark'
  switch (pathology) {
    case 'Tiểu đường':
      return 'badge-light-primary'
    case 'Tim mạch':
      return 'badge-light-success'
    default:
      return 'badge-light-danger'
  }
}

export const getCareplanTypeIcon = (type) => {
  switch (type) {
    case 'DRUG':
      return 'fa-pills'
    case 'NUTRITION':
      return 'fa-utensils'
    case 'EXERCISE':
      return 'fa-running'
    case 'MEASUREMENT':
      return 'fa-ruler'
    case 'OTHER':
      return 'fa-ellipsis-h'
    case 'SLEEP':
      return 'fa-bed'
    default:
      return 'fa-ellipsis-h'
  }
}

export const getReferenceType = (type) => {
  switch (type) {
    case 'DRUG':
      return ['OTHER']
    case 'NUTRITION':
      return ['OTHER']
    case 'EXERCISE':
      return ['STEP', 'OTHER']
    case 'MEASUREMENT':
      return ['BLOOD_PRESSURE', 'BLOOD_GLUCOSE', 'HEART_RATE', 'OTHER']
    case 'SLEEP':
      return ['SLEEP']
    case 'OTHER':
      return ['OTHER']
    default:
      return ['OTHER']
  }
}

export const getReferenceDescription = (type) => {
  switch (type) {
    case 'DRUG':
      return 'Uống thuốc'
    case 'NUTRITION':
      return 'Ăn uống'
    case 'EXERCISE':
      return 'Tập luyện'
    case 'MEASUREMENT':
      return 'Đo lường'
    case 'SLEEP':
      return 'Giấc ngủ'
    case 'OTHER':
      return 'Khác'
    default:
      return 'Khác'
  }
}

export const mapReferenceType = (type) => {
  switch (type) {
    case 'BLOOD_PRESSURE':
      return 'Huyết áp'
    case 'BLOOD_GLUCOSE':
      return 'Đường huyết'
    case 'HEART_RATE':
      return 'Nhịp tim'
    case 'STEP':
      return 'Bước chân'
    case 'SLEEP':
      return 'Giấc ngủ'
    case 'OTHER':
      return 'Khác'
    default:
      return 'Khác'
  }
}

export const getPaymentMethodDescription = (method) => {
  switch (method) {
    case 'FPT_PAY':
      return 'Ví FPT Pay'
    case 'DOMESTIC':
      return 'Thẻ nội địa'
    case 'INTERNATIONAL':
      return 'Thẻ quốc tế'
    case 'VIETQR':
      return 'VietQR'
    case 'DOMESTIC_ACCOUNT':
      return 'Tài khoản ngân hàng'
    case 'SHOPEEPAY':
      return 'Ví ShopeePay'
    case 'MOMO':
      return 'Ví MoMo'
    case 'VIETTEL_MONEY':
      return 'Ví Viettel Money'
    case 'ZALOPAY':
      return 'Ví ZaloPay'
    case 'OTHER':
      return 'Khác'
    default:
      return 'Khác'
  }
}

export const getServiceDescription = (service) => {
  switch (service) {
    case 'TELEHEALTH':
      return 'Tư vấn online'
    case 'OTHER':
      return 'Khác'
    default:
      return 'Khác'
  }
}

export const getPaymentStatusDescription = (status) => {
  switch (status) {
    case 'PAYMENT_WAITING':
      return 'Chờ xử lý'
    case 'PAYMENT_SUCCESS':
      return 'Thành công'
    case 'PAYMENT_FAILED':
      return 'Thất bại'
    case 'PAYMENT_CANCELED':
      return 'Đã hủy'
    case 'PAYMENT_REFUNDED':
      return 'Đã hoàn tiền'
    default:
      return 'Khác'
  }
}

export const getPaymentStatusColor = (status) => {
  switch (status) {
    case 'PAYMENT_WAITING':
      return 'warning'
    case 'PAYMENT_SUCCESS':
      return 'success'
    case 'PAYMENT_FAILED':
      return 'danger'
    case 'PAYMENT_CANCELED':
      return 'danger'
    case 'PAYMENT_REFUNDED':
      return 'secondary'
    default:
      return 'secondary'
  }
}

export const getAppointmentStatusDescription = (status) => {
  switch (status) {
    case "PAYMENT_PROCESS":
      return ["Đang thanh toán", ""]
    case "PAYMENT_SUCCESS":
      return ["Đã đặt hẹn", ""]
    case "PAYMENT_FAIL":
      return ["Hủy", ""]
    case "WAITING_CONCLUSION":
      return ["Chờ tư vấn", ""]
    case "WAITING_TEST_RESULT":
      return ["Chờ kết luận", "Quá hạn nộp kết quả CLS"]
    case "SUBMITTED_TEST_RESULT":
      return ["Chờ kết luận", "Đã nộp kết quả CLS"]
    case "MISSING_CALL":
      return ["Quá giờ hẹn", ""]
    case "FINISHED":
      return ["Hoàn thành", ""]
  }
  return ["Khác", ""]
}

export const getAppointmentStatusClass = (status) => {
  switch (status) {
    case "PAYMENT_PROCESS":
    case "PAYMENT_SUCCESS":
    case "WAITING_CONCLUSION":
    case "WAITING_TEST_RESULT":
    case "SUBMITTED_TEST_RESULT":
      return "text-primary"
    case "PAYMENT_FAIL":
    case "MISSING_CALL":
      return "text-danger"
    case "FINISHED":
      return "text-success"
  }
}

export const getAppointmentStatusLabel = (status) => {
  switch (status) {
    case "PAYMENT_PROCESS":
      return "Đang thanh toán"
    case "PAYMENT_SUCCESS":
      return "Đã đặt hẹn"
    case "PAYMENT_FAIL":
      return "Hủy"
    case "WAITING_CONCLUSION":
      return "Chờ tư vấn"
    case "WAITING_TEST_RESULT":
      return "Chờ kết quả CLS"
    case "SUBMITTED_TEST_RESULT":
      return "Chờ kết luận"
    case "MISSING_CALL":
      return "Quá giờ hẹn"
    case "FINISHED":
      return "Hoàn thành"
  }
  return "Không rõ"
}

export const getFileTypeDescription = (type: string) => {
  switch (type) {
    case 'image':
      return 'Hình ảnh'
    case 'video':
      return 'Video'
    case 'document':
    case 'pdf':
      return 'Tài liệu'
    case 'folder':
      return 'Thư mục'
    default:
      return 'Không xác định'
  }
}

const API_URL = process.env.REACT_APP_API_URL
export const USER_API = `${API_URL}/user`
export const PATIENT_API = `${API_URL}/api/portal/v2/account/patient`
export const PROFILE_API = `${API_URL}/api/portal/v2/account/profile`
export const ACCOUNT_SUMMARY_API = `${API_URL}/api/portal/v2/account/profile/summary`
export const AVATAR_API = `${API_URL}/api/portal/v2/account/avatar`
export const ACCOUNT_API = `${API_URL}/api/portal/v2/account`
export const EMPLOYEE_API = `${API_URL}/api/portal/v2/account/employee`
export const ROLE_CHANGE_API = `${API_URL}/api/portal/v2/account/changerole`
export const DEACTIVE_ACCOUNT_API = `${API_URL}/api/portal/v2/account/deactivate`
export const REACTIVE_ACCOUNT_API = `${API_URL}/api/portal/v2/account/reactivate`
export const RESET_PASSWORD_API = `${API_URL}/api/portal/v2/app/resetpassword`
export const CHANGE_PASSWORD_API = `${API_URL}/api/portal/v2/app/changepassword`
export const STEP_SUMMARY_API = `${API_URL}/api/healthprofile/v2/step/summary`
export const HEARTRATE_SUMMARY_API = `${API_URL}/api/healthprofile/v2/heartrate/summary`
export const SLEEP_SUMMARY_API = `${API_URL}/api/healthprofile/v2/sleep/summary`
export const RESPIRATION_SUMMARY_API = `${API_URL}/api/healthprofile/v2/respiration/summary`
export const PULSEOX_SUMMARY_API = `${API_URL}/api/healthprofile/v2/pulseox/summary`
export const BLOODPRESSURE_SUMMARY_API = `${API_URL}/api/healthprofile/v2/bloodpressure/summary`
export const BLOODGLUCOSE_SUMMARY_API = `${API_URL}/api/healthprofile/v2/bloodglucose/summary`
export const STRESS_SUMMARY_API = `${API_URL}/api/healthprofile/v2/stress/summary`
export const MEDICAL_FILES_API = `${API_URL}/api/healthprofile/v2/medicalhistory/files`
export const CAREPLAN_API = `${API_URL}/api/careplan/v2/careplan`
export const CAREPLAN_SUMMARY_API = `${API_URL}/api/careplan/v2/careplan/summary`
export const DASHBOARD_SUMMARY_API = `${API_URL}/api/portal/v2/dashboard/summary`
export const CHAT_LIST_CONVERSATION = `${API_URL}/api/livechat/v2/conversations`
export const CHAT_USER_CONVERSATION = `${API_URL}/api/livechat/v2/message`
export const CHAT_ADD_MESSAGE = `${API_URL}/api/livechat/v2/message`
export const CHAT_ADD_MESSAGE_V2 = `${API_URL}/api/livechat/v2/app/message`
export const MEDIA_CONVERT_URL = `${API_URL}/api/livechat/v2/attachment/url`
export const ASSIGN_SUPPORT = `${API_URL}/api/livechat/v2/assigntask`
export const FOLLOW_API = `${API_URL}/api/portal/v2/account/follow`
export const FOLLOW_CHECK_API = `${API_URL}/api/portal/v2/account/follow/check`
export const FOLLOW_RELATION_API = `${API_URL}/api/portal/v2/account/follow/relation`
export const MEDICAL_HISTORY_API = `${API_URL}/api/healthprofile/v2/medicalhistory`

export const OCR_URL_API = `${API_URL}/api/ocr/v2/predict/url`
export const OCR_RESULT_API = `${API_URL}/api/ocr/v2/result`
export const UPDATE_MESSAGE_METADATA = `${API_URL}/api/livechat/v2/message/metadata`

export const AI_SUGGESTION_API = `${API_URL}/api/chatbot/v2/question`

export const REGISTER_FIREBASE_API = `${API_URL}/api/notification/v2/devicetoken`

export const GPT_COMPLETIONS_API = `${API_URL}/api/chatbot/v2/gpt/chat`
export const GPT_RESULT_API = `${API_URL}/api/chatbot/v2/chat/result`

export const NOTE_LIST_API = `${API_URL}/api/portal/v2/journal`

export const ORG_API = `${API_URL}/api/portal/v2/organization`
export const ORG_PICTURE_API = `${API_URL}/api/portal/v2/organization/avatar`
export const DEPARTMENT_API = `${API_URL}/api/portal/v2/department`
export const DEPARTMENT_DETAIL_API = `${API_URL}/api/portal/v2/department/detail`
export const DEPARTMENT_EMPLOYEE_UPDATE_API = `${API_URL}/api/portal/v2/department/employee`
export const DEPARTMENT_EMPLOYEE_API = `${API_URL}/api/portal/v2/department/employee/exist`
export const DEPARTMENT_EMPLOYEE_OUTSIDE_API = `${API_URL}/api/portal/v2/department/employee/outside`
export const WORK_PLAN_API = `${API_URL}/api/telehealth/v2/workplan`
export const REMOVE_WORK_PLAN_API = `${API_URL}/api/telehealth/v2/workplan/shift`
export const APPOINTMENT_API = `${API_URL}/api/telehealth/v2/appointment`
export const APPOINTMENT_DETAIL_API = `${API_URL}/api/telehealth/v2/appointment/id`
export const APPOINTMENT_CONVERSATION_API = `${API_URL}/api/livechat/v2/conversation/appointment`
export const CONVERSATION_DETAIL_API = `${API_URL}/api/livechat/v2/conversation/id`
export const APPOINTMENT_JOIN_CHECK = `${API_URL}/api/telehealth/v2/appointment/call/check`
export const APPOINTMENT_HISTORY_API = `${API_URL}/api/telehealth/v2/appointment/history`
export const APPOINTMENT_PENDING_API = `${API_URL}/api/telehealth/v2/appointment/important/handled`
export const APPOINTMENT_PENDING_COUNTER_API = `${API_URL}/api/telehealth/v2/appointment/important/handled/count`

export const PAYMENT_HISTORY_API = `${API_URL}/api/payment/v2/history`

export const SHARE_DATA_PERMISSION_STATUS = `${API_URL}/api/healthprofile/v2/user/permission/status`
export const SHARE_DATA_REQUEST_LIST = `${API_URL}/api/healthprofile/v2/request/waiting`
export const SHARE_DATA_PERMISSION_REQUEST = `${API_URL}/api/healthprofile/v2/request/permission`
export const SHARE_DATA_PERMISSION_CANCEL = `${API_URL}/api/healthprofile/v2/request/cancel`

export const DISEASE_GROUP_API = `${API_URL}/api/telehealth/v2/disease/group`
export const DISEASE_GROUP_DETAIL_API = `${API_URL}/api/telehealth/v2/disease/group/id`
export const DISEASE_ORG_REGISTERED_API = `${API_URL}/api/telehealth/v2/disease/org/registered`
export const DISEASE_ORG_UNREGISTERED_API = `${API_URL}/api/telehealth/v2/disease/org/unregistered`

export const DISEASE_ORG_UPDATE_API = `${API_URL}/api/telehealth/v2/disease/org`
export const DISEASE_EMPLOYEE_API = `${API_URL}/api/telehealth/v2/disease/group/user`
export const DISEASE_EMPLOYEE_CHANGE_BOOKING_API = `${API_URL}/api/telehealth/v2/disease/user/change/booking`
export const DISEASE_EMPLOYEE_OUTSIDE_API = `${API_URL}/api/telehealth/v2/disease/user/outside`

export const MEDICINE_LIST_API = `${API_URL}/api/catalogue/v2/vn/drugs`
export const ICD9_LIST_API = `${API_URL}/api/catalogue/v2/vn/icd9`

export const FORM_LIST_API = `${API_URL}/api/v2/tools/form`
export const FORM_API = `${API_URL}/api/v2/tools/form`
export const FORM_ATTACH_API = `${API_URL}/api/v2/tools/form/attach/disease`
export const FORM_DETACH_API = `${API_URL}/api/v2/tools/form/detach/disease`

export const DASHBOARD_GENERAL_API = `${API_URL}/api/telehealth/v2/dashboard/general`
export const DASHBOARD_DISEASE_API = `${API_URL}/api/telehealth/v2/dashboard/disease`

export const NOTIFICATION_API = `${API_URL}/api/notification/v2/your/notification`
export const NOTIFICATION_MARK_SEEN_API = `${API_URL}/api/notification/v2/your/notification/seen`
export const NOTIFICATION_UNREAD_LIST_API = `${API_URL}/api/notification/v2/your/notification/unseen`
export const NOTIFICATION_MARK_SEEN_ALL_API = `${API_URL}/api/notification/v2/your/notification/seen/all`

export const DEGREE_LIST_API = `${API_URL}/api/catalogue/v2/degree`
export const DOCTOR_SUMMARY_API = `${API_URL}/api/telehealth/v2/doctor/summary`
export const DISEASE_PRICE_MODE_API = `${API_URL}/api/telehealth/v2/disease/price/mode`
export const DISEASE_PRICE_API = `${API_URL}/api/telehealth/v2/disease/price`

export const FORGOT_PASSWORD_API = `${API_URL}/api/auth/v2/empl/forgot/password`
export const USER_RESET_PASSWORD_API = `${API_URL}/api/auth/v2/empl/submit/password`

export const MAKE_S3_API = `${API_URL}/api/telehealth/v2/appointment/attachment/url`

export const ACTIVITY_HISTORY_API = `${API_URL}/api/notification/v2/entity/activity`
export const ICD10_LIST_API = `${API_URL}/api/catalogue/v2/icd10`
export const DRUG_ROUTE_API = `${API_URL}/api/catalogue/v2/drug/route`

export const CONCLUSION_API = `${API_URL}/api/telehealth/v2/appointment/conclusion`

export const WORKING_TIME_API = `${API_URL}/api/telehealth/v2/working/schedule`
export const DOCTOR_SHIFT_API = `${API_URL}/api/telehealth/v2/workplan/shift`
export const DOCTOR_SHIFT_AVAILABLE_API = `${API_URL}/api/telehealth/v2/schedule/available`

export const DOCTOR_RATING_LIST_API = `${API_URL}/api/telehealth/v2/appointment/rating`
export const NEWSFEED_API = `${API_URL}/api/newfeeds/v2/news`
export const BANNER_API = `${API_URL}/api/newfeeds/v2/banner`
export const FEATURE_API = `${API_URL}/api/newfeeds/v2/feature`

export const GET_EXPORT_HISTORY_API = `${API_URL}/api/export/v2/history`
export const EXECUTE_EXPORT_API = `${API_URL}/api/export/v2/execute`
